import React, { ElementType, ReactNode, createContext, useCallback, useContext, useState } from "react";

import { Rm3ResponseModel } from "./models";
import { reportsAPI } from "../../apiClient/reportsApi";

interface ReportsProviderProps {
	LoadingComponent: ElementType;
	children: ReactNode;
}

type TLoadingStatus = 'idle' | 'loaded' | 'idle' | 'loading';

type ReportsProviderState = {
	loadingStatus: TLoadingStatus,
	notificationText: string,
	setNotificationText: (text: string) => void,
	getRm3Report: (reasonId: number | null) => void;
	rm3ReportData: Rm3ResponseModel[];
};

export const ReportsContext = createContext<ReportsProviderState>({} as ReportsProviderState);
export const useReportsContext = () => useContext(ReportsContext);

export const ReportsProvider: React.FC<ReportsProviderProps> = ({ LoadingComponent, children }) => {
	const [loadingStatus, setLoginStatus] = useState<TLoadingStatus>('loaded');
	const [notificationText, setNotificationText] = useState<string>('');
	const [rm3ReportData, setRm3ReportData] = useState<Rm3ResponseModel[]>([]);

	const getRm3Report = useCallback(
		(reasonId: number | null) => {
			setLoginStatus('loading');
			reportsAPI.rm3(reasonId).then((response) => {
				setLoginStatus('loaded');
				setRm3ReportData(response.data)
			})
		},
		[]
	);


	return (
		<ReportsContext.Provider
			value={{
				loadingStatus,
				notificationText,
				setNotificationText,
				getRm3Report,
				rm3ReportData,
			}}
		>
			{loadingStatus === 'loaded' ? children : <LoadingComponent />}
		</ReportsContext.Provider>
	)
};

