import { Button, Collapse, Select } from 'antd';
import React, { useState } from "react";

import type { CollapseProps } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import { Rm3ReportReasons } from "../models";
import dayjs from 'dayjs';
import { getRm3DataAsItems } from '../helpers/getRm3DataAsItems';
import style from "./rm3Report.module.scss";
import { useReportsContext } from "../reportsContext";

interface Rm3ItemProps {
	title: string;
	values: { subtitle: string, value: number }[];
}

const Rm3Item: React.FC<Rm3ItemProps> = ({ title, values }) => {
	return (
		<div className={style.item_container}>
			{title && <div className={style.item_title}>{title}</div>}
			{values.map((el, i) => (
				<div key={i} className={style.sub_item}>
					<div className={style.sub_item_title}>{el.subtitle} - </div>
					<div className={style.sub_item_value}>{el.value}</div>
				</div>
			))}
		</div>
	)
};

const Rm3Report = () => {
	const { getRm3Report, rm3ReportData } = useReportsContext();

	const [reasonId, setReasonId] = useState<number | null>(null);

	const onCreateReportClick = () => {
		getRm3Report(reasonId)
	}

	const colapseItems: CollapseProps['items'] = getRm3DataAsItems(rm3ReportData).map(el => {
		return {
			key: el[0].departmentName,
			label: el[0].departmentName,
			children: el.map((x: any, i: number) => <Rm3Item key={i + 1} title={x.title} values={x.values} />),
		};

	});

	return (
		<div className={style.temp_rm3_container}>
			<div className={style.report_date}>Дата звіту (РМ-3): <span>{dayjs().format('YYYY-MM-DD HH:mm')}</span></div>

			<div className={style.reason_selector_container}>
				<Select className={style.reason_selector}
					placeholder='Оберіть причину'
					value={reasonId}
					style={{ width: 150 }}
					onChange={(value) => setReasonId(value)}
					options={[
						{ key: 1, value: Rm3ReportReasons.SCHEDULES, label: 'Графіки' },
						{ key: 2, value: Rm3ReportReasons.TECHNICAL_VIOLATIONS, label: 'Технічні порушення' },
						{ key: 3, value: Rm3ReportReasons.WAR_DESTRUCTION, label: 'Бойові дії' },
					]}
					allowClear
				/>

				<Button className={style.app_button}
					onClick={onCreateReportClick}
					icon={<DatabaseOutlined />}
				>
					Cформувати
				</Button>

			</div>

			<div className={style.mobile_container}>
				{
					rm3ReportData.length === 0 ? <div>Дані відсутні, сформуйте звіт</div> : rm3ReportData.some(x => x.departmentId !== 0)
						? <Collapse items={colapseItems} style={{ backgroundColor: "#f5f5f5" }} />
						: <div>Відключення відсутні</div>
				}
			</div>
		</div >
	);
};

export default Rm3Report;
