import { useCallback, useEffect, useState } from "react";

import { notification } from 'antd';

type NotificationType = 'success' | 'error' | 'info' | 'warning' | undefined

interface IMessage {
	type: NotificationType;
	description: string;
}

export const useNotify = () => {
	const [api, contextHolder] = notification.useNotification();
	const [message, setMessage] = useState<IMessage>({ type: undefined, description: '' });

	const openNotification = useCallback((text: string, type: NotificationType) => {
		setMessage({ type, description: text })
	}, []);

	useEffect(() => {
		if (message.type === 'success') {
			api.success({
				message: `Успіх`,
				description: message.description,
			});
		}

		if (message.type === 'error') {
			api.error({
				message: `Помилка`,
				description: message.description,
			});
		}

		if (message.type === 'warning') {
			api.warning({
				message: `Увага`,
				description: message.description,
			});
		}

		if (message.type === 'info') {
			api.info({
				message: `Інформація`,
				description: message.description,
			});
		}

	}, [api, message.description, message.type])

	return { openNotification, contextHolder };
}