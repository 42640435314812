import { Layout, Menu, Popover } from 'antd';
import React, { ReactNode, useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import logo from "../../logo.png";
import style from "./appLayout.module.scss";
import { useAppContext } from '../../appContext';

const { Header, Content } = Layout;

interface AppLayoutProps {
	children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
	const { signOut } = useAppContext();

	const [menuVisible, setMenuVisible] = useState(false);

	const menuItems = [
		{ key: '1', label: <NavLink to={"/rm3report"}>Звіт РМ-3</NavLink>, },
		{ key: '2', label: 'Вихід', style: { fontWeight: 'bold', borderTop: '1px solid #eee' }, onClick: () => signOut() },
	];

	const handleMenuClick = () => {
		setMenuVisible(!menuVisible);
	};

	const closeMenu = () => {
		setMenuVisible(false);
	};

	return (
		<Layout className={style.app_layout_container}>
			<Header className={style.header}>
				<div className={style.logo}><img src={logo} alt="logo" /></div>
				<div className={style.burger_menu}>
					<Popover
						content={
							<Menu onClick={closeMenu} items={menuItems} style={{ border: 'none' }} />
						}
						placement="bottomRight"
						trigger="click"
						open={menuVisible}
						onOpenChange={(visible) => setMenuVisible(visible)}
					>
						<MenuOutlined onClick={handleMenuClick} />
					</Popover>
				</div>
			</Header>
			<Content className={style.content}>{children}</Content>
		</Layout>
	);
};

export default AppLayout;
