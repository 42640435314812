import { Rm3ResponseModel } from "../models";

export const getRm3DataAsItems = (rm3ResponseData: Rm3ResponseModel[]): any[] => {
	if (!rm3ResponseData.length) return [];

	const result: any[] = [];

	rm3ResponseData.forEach(item => {
		result.push([
			{
				departmentName: item.departmentName,
				departmentId: item.departmentId,
				title: "Підстанції",
				values: [
					{ subtitle: "ПЛ-110кВ", value: item.pl110 },
					{ subtitle: "ПC-110кВ", value: item.ps110 },
					{ subtitle: "ПЛ-35кВ", value: item.pl35 },
					{ subtitle: "ПC-35кВ", value: item.ps35 },
				],
			},
			{
				title: "ПЛ-10кВ",
				values: [
					{ subtitle: "Всього", value: item.pl10Total },
					{ subtitle: "У т.ч. частково", value: item.pl10Partly },
					{ subtitle: "Більше доби", value: item.pl10MoreThenDay },
				],
			},
			{
				title: "ТП",
				values: [
					{ subtitle: "Всього", value: item.tp },
					{ subtitle: "Більше доби", value: item.tpMoreThenDay },
				],
			},
			{
				title: "Населені пункти",
				values: [
					{ subtitle: "Всього", value: item.settlementsTotal },
					{ subtitle: "У т.ч. частково", value: item.settlementsPartly },
					{ subtitle: "Більше доби", value: item.settlementsMoreThenDay },
				],
			},
			{
				title: "Знеструмлені споживачі",
				values: [
					{ subtitle: "Всього", value: item.counterpartiesShutdownsTotal },
					{ subtitle: "Побутові", value: item.residentialCounterpartiesShutdowns },
					{ subtitle: "Непобутові", value: item.commercialCounterpartiesShutdowns },
				],
			},
		]);
	});

	return result;
};
