import axios from "axios";

export const apiClient = () => {
	const token = localStorage.getItem("token");
	let baseURL = "/api";

	if (process.env.REACT_APP_BASE_URL) {
		baseURL = process.env.REACT_APP_BASE_URL + "/api";
	}

	const axiosInstance = axios.create({
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		},
		baseURL: baseURL,
	});

	axiosInstance.interceptors.response.use(
		response => {
			localStorage.setItem("oms-single-page:isAuthenticated", "true");
			return response;
		},

		error => {
			if (error.response?.status === 401) {

				if (!error.request.responseURL.includes('api/Token')) {
					window.location.href = "/login"
				}

				localStorage.setItem("oms-single-page:isAuthenticated", "false");
			}

			if (error.response?.status === 400) {
				const myErrors = error.response?.data; // as IBadRequestResultModel;
				const errorList: string[] = myErrors?.errorInfo?.errors || [];
				errorList?.map((x: string) => {
					console.log("ERRORS: ", x);
					//snackBar(x, { variant: 'error' });
				});
			}

			return Promise.reject(error);
		}
	);

	return axiosInstance;
};
