import React from "react";
import { Spin } from "antd";

const Preloader = () => {
	return (
		<>
			<Spin style={{ position: 'absolute', top: '45%', left: '48%' }} size="large" />
		</>
	);
};

export default Preloader;