import App from './App';
import { AppProvider } from './appContext';
import { ConfigProvider } from 'antd';
import Preloader from './components/preloader';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const theme = {
	token: {
		fontFamily: "Helvetica,Arial,sans-serif",
		fontWeightStrong: 500,
	},
};

root.render(
	<Router>
		<AppProvider LoadingComponent={() => <Preloader />}>
			<ConfigProvider theme={theme}>
				<App />
			</ConfigProvider>
		</AppProvider>
	</Router>
);


reportWebVitals();
