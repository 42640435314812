export interface Rm3ResponseModel {
	departmentId: number;
	departmentName: string;
	pl110: number;
	ps110: number;
	pl35: number;
	ps35: number;
	pl10Total: number;
	pl10Partly: number;
	pl10MoreThenDay: number;
	settlementsTotal: number;
	settlementsPartly: number;
	settlementsMoreThenDay: number;
	tp: number;
	tpMoreThenDay: number;
	counterpartiesShutdownsTotal: number;
	residentialCounterpartiesShutdowns: number;
	commercialCounterpartiesShutdowns: number;
	counterpartiesTotal: number;
}

export enum Rm3ReportReasons {
	SCHEDULES = 1,
	TECHNICAL_VIOLATIONS = 2,
	WAR_DESTRUCTION = 3,
}
