import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import AppLayout from './components/appLayout/AppLayout';
import Login from './pages/login/Login';
import Rm3Container from './pages/rm3Report/Rm3Report';
import style from './app.module.scss'
import { useAppContext } from './appContext';

const App = () => {
	const navigate = useNavigate();
	const { contextHolder } = useAppContext();

	const isAuth = localStorage.getItem('oms-single-page:isAuthenticated') === 'true' ? true : false;

	useEffect(() => {
		if (!isAuth) navigate('/login');
	}, [isAuth, navigate])

	return (
		<div className={style.app}>
			{contextHolder}
			{!isAuth
				? (<Routes>
					<Route element={<Login />} path='/login' />
				</Routes>)

				: (<AppLayout>
					<Routes>
						<Route element={<Rm3Container />} path='/' />
						<Route element={<Rm3Container />} path='/rm3report' />
					</Routes>
				</AppLayout>)
			}
		</div>
	);
}

export default App;
