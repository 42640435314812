import React, { ElementType, ReactNode, createContext, useCallback, useContext, useState } from "react";

import { authAPI } from "./apiClient/authApi";
import { useNavigate } from "react-router-dom";
import { useNotify } from "./hooks/useNotify";

interface AppProviderProps {
	LoadingComponent: ElementType;
	children: ReactNode;
}

type TLoadingStatus = 'idle' | 'loaded' | 'idle' | 'loading';

type AppProviderState = {
	loadingStatus: TLoadingStatus,
	signIn: (login: string, password: string) => void;
	signOut: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	contextHolder: any;
};

export const AppContext = createContext<AppProviderState>({} as AppProviderState);
export const useAppContext = () => useContext(AppContext);


export const AppProvider: React.FC<AppProviderProps> = ({ LoadingComponent, children }) => {
	const { openNotification, contextHolder } = useNotify();
	const navigate = useNavigate();

	const [loadingStatus, setLoginStatus] = useState<TLoadingStatus>('loaded');

	const signIn = useCallback(
		(login: string, password: string) => {
			setLoginStatus('loading');
			authAPI.getToken(login, password).then((response) => {
				localStorage.setItem('token', response.data.token)
				openNotification('Вітаємо у системі!', 'success');
				navigate('/')
			}).catch(() => {
				openNotification('Невірний логін або пароль!', 'error');
			}).finally(() => {
				setLoginStatus('loaded');
			})
		},
		[navigate, openNotification]
	);

	const signOut = useCallback(
		() => {
			localStorage.setItem('token', '')
			localStorage.setItem("oms-single-page:isAuthenticated", "false");
			navigate('/login')
			openNotification('Сеанс завершено!', 'info');
		},
		[navigate, openNotification]
	);

	return (
		<AppContext.Provider
			value={{
				loadingStatus,
				signIn,
				signOut,
				contextHolder,
			}}
		>
			{loadingStatus === 'loaded' ? children : <LoadingComponent />}
		</AppContext.Provider>
	)
};

