import Preloader from "../../../components/preloader";
import React from "react";
import { ReportsProvider } from "../reportsContext";
import Rm3Report from "./Rm3Report";

const Rm3Container: React.FC = () => {

	return (
		<ReportsProvider LoadingComponent={() => <Preloader />}>
			<Rm3Report />
		</ReportsProvider>
	);
};

export default Rm3Container;