import { Button, Form, Input } from "antd";

import React from "react";
import style from "./login.module.scss";
import { useAppContext } from "../../appContext";

const layout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 16 },
};

const Login = () => {
	const { signIn } = useAppContext();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSignIn = (values: any) => {
		signIn(values.login, values.password);
	};

	const onSignInFailed = (errorInfo: object | string) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className={style.login_wrapper}>
			<div className={style.form_title}>Вхід до системи</div>

			<Form
				className={style.form_content}
				{...layout}
				name='basic'
				onFinish={onSignIn}
				onFinishFailed={onSignInFailed}
			>
				<Form.Item label='Логін' name='login' rules={[{ required: true, message: "Будь ласка введіть логін!" }]}>
					<Input />
				</Form.Item>

				<Form.Item
					label='Пароль'
					name='password'
					rules={[{ required: true, message: "Будь ласка введіть пароль!" }]}
				>
					<Input.Password />
				</Form.Item>

				<div style={{ textAlign: "center", paddingBottom: "20px" }}>
					<Button className={style.app_button} type='primary' htmlType='submit'>
						Увійти
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default Login;
